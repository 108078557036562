import { graphql } from "react-apollo";
import CmsBlockQuery from "theme/modules/CmsBlock/CmsBlockQuery.gql";

export const HERO_BANNER_PREFIX = 'hero_banner_';

/**
 * Query hero banner and add it to props.
 */
export default () =>
  graphql(CmsBlockQuery, {
    name: 'heroBanner',
    options: ({ cms: { identifier } = { identifier: ''}}) => {
      return {
        variables: {
          identifiers: [`${HERO_BANNER_PREFIX}${identifier}`]
        },
      }
    },
    props: ({ heroBanner, ownProps : { cms: { identifier } = { identifier: ''}} }) => {

      return {
        heroBanner: {
          loading: heroBanner.loading,
          data:
            !heroBanner.loading &&
            heroBanner.cmsBlockList &&
            heroBanner.cmsBlockList.find(
              (block) => block && block.identifier === `${HERO_BANNER_PREFIX}${identifier}`
            ),
        },
      }
    },
  });